
import * as onfido from "onfido-sdk-ui"
import { ChangeEvent, MouseEvent, useEffect, useState, Fragment, KeyboardEvent } from "react"
import Flag from "../../asset/flag"
import { deleteAuthEmail, getApplicantDetails, getOnfidoSession, getSession, setApplicantField, verifyMsisdn, verifyMsisdnCode} from "../../api"
import { Loader } from "../../component"
import { useNavigate } from "react-router-dom"
import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline"
import { PhoneNumberUtil, PhoneNumberType, PhoneNumberFormat } from "google-libphonenumber"
import Hourglass from "../../asset/hourglass"

interface CreateAccountWizardState {
    questions: Question[]

    stack: Question[]
    values: Map<string, any>

    currentQuestionIndex: number
    loading: boolean
}

interface Question {
    name: string
    component: (values: Map<string, any>) => JSX.Element
    showIf?: (values: Map<string, any>) => boolean
}

interface SubmitInput {
    name: string
    value: string | number | boolean | Object
    waitForServer?: boolean
}

export default function CreateAccountWizard() {
    const navigate = useNavigate()
    const [state, setState] = useState<CreateAccountWizardState>({
        questions: [],
        stack: [],
        loading: true,
        values: new Map<string, string | number | Object>(),
        currentQuestionIndex: 0,
    })

    const updateStack = (values: Map<string, any>): Question[] => {
        const stack: Question[] = []
        for (let i = 0; i < questions.length; i++) {
            const q = questions[i]
            stack.push(q)
            if (!values.get(q.name)) {
                if (q.showIf) {
                    if (q.showIf(values)){
                        break
                    } else {
                        stack.pop()
                        continue
                    }
                }
                break
            }
        }
        return stack
    }
    
    useEffect(() => {
        getSession().then((resp) => {
            if (resp.data.clientAuthenticated) {
                navigate(`/portfolio`)
                return
            }
            getApplicantDetails().then((resp) => {
                const applicant = resp.data.applicant
                const values = new Map<string, any>()
                Object.keys(applicant).map((k) => values.set(k, applicant[k]))
                const stack = updateStack(values)
                setState(prev => ({
                    ...prev, 
                    loading: false,
                    stack,
                    values,
                }))
            }, () => {
                navigate("/")
                return
            })
        }).catch(console.error)
    }, [])

    const handleSubmit = (input: SubmitInput) => {
        const request = Object.fromEntries(state.values.set(input.name, input.value))
        if (input.waitForServer) {
            setState(prev => ({ ...prev, loading: true }))
            setApplicantField(request).then(() => {
                setState((prev) => {
                    const values = new Map<string, string | number | Object>(prev.values).set(input.name, input.value)
                    const stack = updateStack(values)
                    return {
                        ...prev,
                        loading: false,
                        stack,
                        values,
                    }
                })
            }).catch(() => {
                setState(prev => ({ ...prev, loading: false }))
            })
            return
        }
        
        setApplicantField(request).catch(console.error)
        setState((prev) => {
            const values = new Map<string, string | number | Object>(prev.values).set(input.name, input.value)
            const stack = updateStack(values)
            return {
                ...prev,
                loading: false,
                stack,
                values,
            }
        })
    }

    const handleSubmitWithNoSave = (input: SubmitInput) => {
        setState((prev) => {
            const values = new Map<string, string | number | Object>(prev.values).set(input.name, input.value)
            const stack = updateStack(values)
            return {
                ...prev,
                loading: false,
                stack,
                values,
            }
        })
    }

    const handleBack = () => {
        setState(prev => {
            if (prev.stack.length <= 1) {
                return prev
            }
            const lastQuestion = prev.stack[prev.stack.length - 2]
            const values = new Map<string, any>(prev.values)
            // shouldn't happen lastQuestion is undefined
            if (lastQuestion) {
                values.delete(lastQuestion.name)
            }
            const stack = updateStack(values)
            return {
                ...prev,
                stack,
                values,
            }
        })
    }

    const employmentTypeOptions = [
        {label: "Employed", value: "employed"},
        {label: "Self-employed", value: "selfEmployed"},
        {label: "Unemployed", value: "unemployed"},
        {label: "I am a retiree", value: "retiree"},
    ]
    const sourceOfWealthOptions = [
        {label:"Employment", value:"employment"},
        {label:"Business", value:"business"},
        {label:"Savings / Inheritance", value:"savings/inheritance"},
        {label:"Other", value:"other"},
    ]
    const investorCategoryOptions = [
        {
            label: "Accredited investor",
            value: "accreditedInvestor",
        },
        {
            label: "High net-worth investor",
            value: "highNetworthInvestor",
        },
        {
            label: "Sophisticated investor - RM 250k",
            value: "sophisticatedInvestor250k",
        },
        {
            label: "None of the above",
            value: "retailInvestor",
        },
    ]
    const investorCategoryDetails = () => <div className="flex flex-col w-full gap-3 bg-gray-50 border border-gray-300 p-3 rounded-3xl mb-5">
        <div>
            <h3 className="text-sm font-semibold text-gray-700">Accredited investor</h3>
            <ul className="text-sm">
                <li>Licensed (CMSRL) or Registered persons, including CEOs/Directors of CMSLs</li>
            </ul>
        </div>
        <div>
            <h3 className="text-sm font-semibold text-gray-700">High net-worth investor</h3>
            <ul className="text-sm">
                <li>Annual Income of {">"}RM300,000 (Individual)</li>
                <li>or {">"}RM400,000 (Households) </li>
                <li>or {">"}RM1,000,000 investment portfolio</li>
                <li>or {">"}RM3,000,000 net personal assets.</li>
            </ul>
        </div>
        <div>
            <h3 className="text-sm font-semibold text-gray-700">Sophisticated investor - RM 250k</h3>
            <ul className="text-sm">
                <li>Any investor who can invest RM250k or more for each transaction.</li>
            </ul>
        </div>
    </div>
    const annualIncomeOptions = [
        {
            label: "RM0 to RM179,999",
            value: "rm0torm179k",
        },
        {
            label: "RM180,000 to RM299,999",
            value: "rm180ktorm299k",
        },
        {
            label: "RM300,000 to RM499,999",
            value: "rm300ktorm499k",
        },
        {
            label: "Above RM500,000",
            value: "aboverm500k",
        },
    ]
    const natureOfBusinessOptions = [
        {
            label: "Schools and educational institutions",
            value: "NB01 - Schools and educational institutions",
        },
        {
            label: "Hospitals, medical centers, and other healthcare facilities",
            value: "NB02 - Hospital, medical centres, healthcares",
        },
        {
            label: "Government-related organisations",
            value: "NB03 - Government related organisation",
        },
        {
            label: "Private banking (ie. banks providing personalised financial and banking services to high net-worth individual clients)",
            value: "NB04 - Private banking, i.e. banks providing personalised financial and banking services to high net-worth individual clients",
        },
        {
            label: "Cash-intensive businesses that primarily deal in physical cash transactions for their goods or services (e.g. convenience store, restaurants, car workshops, hawkers, pawnshop etc.)",
            value: "NB05 - Cash intensive business, i.e. the business receives payments for services and/or goods sold in physical cash (e.g. convenience store, restaurants, car workshops, hawkers, pawnshop etc.)",
        },
        {
            label: "Casinos, betting centers, and other activities related to gambling, including the iGaming industry.",
            value: "NB06 - Casino, betting centre, gambling, iGaming industry and other gambling related activities",
        },
        {
            label: "Nightclubs, karaoke centre, lounge, sauna, bar, massage parlour",
            value: "NB07 - Nightclubs, karaoke centre, lounge, sauna, bar, massage parlour",
        },
        {
            label: "Money service business, such as non-bank money lending and money transfer services.",
            value: "NB08 - Money service business (e.g. non-bank money leasing, money transfer business)",
        },
        
        {
            label: "Crypto/cryptocurrencies or forex industry",
            value: "NB09 - Crypto/cryptocurrencies or forex industry",
        },
        {
            label: "Dealers (both individual and corporate) in valuable or precious goods (jewels, gems, art, antique, and real estate)",
            value: "NB10 - Dealers (both individual and corporate) in valuable or precious goods (jewels, gems, art, antique, and real estate)",
        },
        {
            label: "Distributors of cigarettes, tobacco-related products, and vape stores",
            value: "NB11 - Cigarette and tobacco related products distributors, vapes stores",
        },
        {
            label: "Arms or weapons-related business",
            value: "NB12 - Arms or weapon related business",
        },
        {
            label: "Scrap metal-related business",
            value: "NB13 - Scrap metal related business",
        },
        {
            label: "Used car dealers, boats and yachts selling companies, planes manufacturers",
            value: "NB14 - Used car dealers, boats and yachts selling companies, planes manufacturers",
        },
        {
            label: "Forwarding agents, including companies specialising in the collection, shipment, and delivery of goods, such as logistics and freight forwarding businesses",
            value: "NB15 - Forwarding agents (e.g. companies engaged in collection, shipment and delivery of goods, including logistic companies and freight forwarding companies)",
        },
        {
            label: "None of the above",
            value: "NB99 - None of the above",
        },
    ]
    const pepOrRelatedToOptions = [
        { label: "No, I'm neither a politician nor related to a politician", value: "notPepNotRelated" },
        { label: "Yes, I'm a related to a politician", value: "yesRelatedToPep" },
        { label: "Yes, I'm a politician", value: "yesPep" },
    ]
    const questions: Question[] = [
        {
            name: "blocked",
            component: () => <QuestionBlocked />,
            showIf: (values) => values.get("status") === "blocked"
        },
        {
            name: "title",
            component: () => <QuestionText name="title" text="Your email is verified! How should we address you?" placeholder="Satoshi" handleSubmit={handleSubmit} />,
        },
        {
            name: "msisdn",
            component: (values) => <QuestionMsisdn name="msisdn" text={`Nice to meet you, ${values.get("title")}! Can we have your mobile number?`} placeholder="11 XXXX XXXX" handleSubmit={handleSubmit} handleSubmitWithNoSave={handleSubmitWithNoSave} />,
        },
        {
            name: "verifyMsisdn",
            component: (values) => <QuestionVerifyMsisdn name="verifyMsisdn" text="Please provide the verification code sent to your mobile number." placeholder="123 456" handleSubmit={handleSubmit} handleSubmitWithNoSave={handleSubmitWithNoSave} msisdnCountryCodeLetters={values.get("msisdn")?.countryCodeLetters} msisdnInternationalFormat={values.get("msisdn")?.internationalFormat} />,
        },
        {
            name: "onfido",
            component: () => <QuestionOnfido text="" name="onfido" handleSubmit={handleSubmit} />,
        },
        {
            name: "employmentType",
            component: () => <QuestionSelect text="What's your current employment status?" name="employmentType" handleSubmit={handleSubmit} options={employmentTypeOptions} />,
        },
        {
            name: "companyName",
            component: (values) => <QuestionText name="companyName" text={values.get("employmentType") === "retiree" ? "What was your last employer's name?" : "What's your employer's name?"} placeholder="Nakamato Sdn. Bhd." handleSubmit={handleSubmit} />,
            showIf: (values) => values.get("employmentType") === "employed"  || values.get("employmentType") === "selfEmployed" || values.get("employmentType") === "retiree"
        },
        {
            name: "natureOfBusiness",
            component: (values) => <QuestionSelect name="natureOfBusiness" text={`What's the nature of business of ${values.get("companyName") || "last company you worked for"}?`} handleSubmit={handleSubmit} options={natureOfBusinessOptions}/>,
            showIf: (values) => values.get("companyName")
        },
        {
            name: "otherNatureOfBusiness",
            component: (values) => <QuestionText text={`Please provide the nature of business of ${values.get("companyName") || "last company you worked for"}?`} name="otherNatureOfBusiness" placeholder="Nature of business.." handleSubmit={handleSubmit} />,
            showIf: (values) => values.get("companyName") && values.get("natureOfBusiness") === "NB99 - None of the above"
        },
        {
            name: "designation",
            component: (values) => <QuestionText name="designation" text={`What is your role at ${values.get("companyName") || "last company you worked for"}?`} placeholder="Engineer" handleSubmit={handleSubmit} />,
            showIf: (values) => values.get("companyName")
        },
        {
            name: "annualIncome",
            component: () => <QuestionSelect name="annualIncome" text="What's your annual income?" handleSubmit={handleSubmit} options={annualIncomeOptions}/>,
            showIf: (values) => values.get("companyName")
        },
        {
            name: "sourceOfWealth",
            component: () => <QuestionSelect text="What's your source of wealth?" multiple={true} name="sourceOfWealth" handleSubmit={handleSubmit} options={sourceOfWealthOptions} />,
        },
        {
            name: "otherSourceOfWealth",
            component: () => <QuestionText text="Please provide your other source of wealth." name="otherSourceOfWealth" placeholder="Source of wealth.." handleSubmit={handleSubmit} />,
            showIf: (values) => (values.get("sourceOfWealth") as string[])?.filter(s => s === "other").length > 0
        },
        {
            name: "pepOrRelatedToPep",
            component: () => <QuestionSelect name="pepOrRelatedToPep" text="Are you a politician or related to a politician?" handleSubmit={handleSubmit} options={pepOrRelatedToOptions}/>,
        },
        {
            name: "relatedPepName",
            component: () => <QuestionText name="relatedPepName" text="What's the name of the politician you are related to?" handleSubmit={handleSubmit}/>,
            showIf: (values) => values.get("pepOrRelatedToPep") === "yesRelatedToPep"
        },
        {
            name: "relatedPepPosition",
            component: (values) => <QuestionText name="relatedPepPosition" text={`What's ${values.get("relatedPepName")} position?`} handleSubmit={handleSubmit}/>,
            showIf: (values) => values.get("pepOrRelatedToPep") === "yesRelatedToPep"
        },
        {
            name: "relatedPepOrganisation",
            component: (values) => <QuestionText name="relatedPepOrganisation" text={`What's ${values.get("relatedPepName")} organisation?`} handleSubmit={handleSubmit}/>,
            showIf: (values) => values.get("pepOrRelatedToPep") === "yesRelatedToPep"
        },
        {
            name: "relatedPepRelationshipWithApplicant",
            component: (values) => <QuestionText name="relatedPepRelationshipWithApplicant" text={`What's your relation with ${values.get("relatedPepName")}?`} handleSubmit={handleSubmit}/>,
            showIf: (values) => values.get("pepOrRelatedToPep") === "yesRelatedToPep"
        },
        {
            name: "investorCategory",
            component: () => <QuestionSelect text="Which investor category best describes you?" name="investorCategory" detailsRenderer={investorCategoryDetails} handleSubmit={handleSubmit} options={investorCategoryOptions}/>,
        },
        {
            name: "accreditedLicenseNumber",
            component: (values) => <QuestionText text={`What is your license number ?`} name="accreditedLicenseNumber" placeholder="Type your license number" handleSubmit={handleSubmit} />,
            showIf: (values) => values.get("investorCategory") && values.get("investorCategory") === "accreditedInvestor"
        },
        {
            name: "declaration",
            component: () => <QuestionDeclaration name="declaration" text="" handleSubmit={handleSubmit}/>,
        },
        {
            name: "outro",
            component: () => <Outro name="outro" text="" handleSubmit={handleSubmit}/>,
        },
    ]

    if (state.loading) {
        return <div className="flex justify-center items-center h-full">
            <Loader classNames="w-12 h-12 text-blue-50" />
        </div>
    }

    const lastQuestion = state.stack[state.stack.length - 1]
    if (lastQuestion.name === "onfido") {
        return lastQuestion.component(state.values)
    }

    return <div className="flex flex-col md:w-1/2 p-5 mx-auto h-full">
        {state.stack.length >= 2 && lastQuestion.name !== "employmentType" && lastQuestion.name !== "outro" && lastQuestion.name !== "blocked" && <div className="relative mb-10">
            <ArrowLeftCircleIcon className="fixed w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={handleBack}/>
        </div>}

        {lastQuestion.component(state.values)}
    </div>
}

function QuestionBlocked() {
    return <div className="flex flex-col xs:justify-between sm:justify-between md:justify-normal md:gap-20 h-full">
        <div className="flex flex-col items-start w-full gap-6">
            <h3 className="text-blue-800 font-medium text-xl">
                You were blocked due to exceeding the verification attempts. If you believe this is an error, please contact <a href="mailto:support@halogen.my" className="underline">support@halogen.my</a>
            </h3>
        </div>
    </div>
}

interface QuestionProps {
    name: string
    text: string
    subtext?: string
    placeholder?: string
    handleSubmit: (input: SubmitInput) => void
    handleSubmitWithNoSave?: (input: SubmitInput) => void
}

function QuestionText(props: QuestionProps) {
    const [value, setValue] = useState("")
    const handleOnChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setValue(ev.currentTarget?.value)
    }
    const submitDisabled = !value || value === ""
    const handleSubmit = () => {
        if (submitDisabled) {
            return
        }
        const input= {name: props.name, value}
        props.handleSubmit(input)
        setValue("")
    }
    const handleKeyDown = (ev: KeyboardEvent<HTMLInputElement>) => {
        if (ev.key !== "Enter") {
            return
        }
        handleSubmit()
    }

    return <div className="flex flex-col xs:justify-between sm:justify-between md:justify-normal md:gap-20 h-full">
        <div className="flex flex-col items-start w-full gap-6">
            <div className="flex flex-col gap-2">
                <h3 className="text-blue-800 font-medium text-xl">
                    {props.text}
                </h3>
                {props.subtext && <h6 className="text-blue-800 font-medium text-md">
                    {props.subtext}
                </h6>}
            </div>
            <input type="text" onKeyDown={handleKeyDown} autoFocus={true} value={value} className="w-full p-3 hg-border-blue rounded-full" placeholder={props.placeholder} onChange={handleOnChange}/>
        </div>
        <div className="w-full">
            <button onClick={handleSubmit} disabled={submitDisabled} className="w-full p-3 hg-bg-blue text-white rounded-full disabled:bg-blue-300">Next</button>
        </div>
    </div>
}

interface QuestionMsisdnState {
    listVisible: boolean
    examplePhoneNumber: string
    msisdnValid: boolean
    countryCode: string

    loading: boolean
    blocked: boolean

    msisdn: {
        internationalFormat: string
        countryCodeLetters: string
        value: string
    }
}
function QuestionMsisdn(props: QuestionProps) {
    const [state, setState] = useState<QuestionMsisdnState>({
        loading: false,
        blocked: true,
        listVisible: false,
        msisdnValid: false,
        examplePhoneNumber: "12-345 6789",
        countryCode: "+60",
        msisdn:{
            countryCodeLetters: "my",
            internationalFormat: "",
            value: "",
        },
    })
    
    const handleCountryCodeChange = (ev: MouseEvent<HTMLLIElement>) => {
        const {letters, code} = ev.currentTarget.dataset
        if (!letters || !code) return
        let examplePhoneNumber = "XXX XXX XXX"
        try{
            const i = PhoneNumberUtil.getInstance()
            examplePhoneNumber = i.format(i.getExampleNumberForType(letters.toUpperCase(), PhoneNumberType.MOBILE), PhoneNumberFormat.NATIONAL)
            if (examplePhoneNumber.charAt(0) === "0") {
                examplePhoneNumber = examplePhoneNumber.slice(1, examplePhoneNumber.length -1)
            }
        } catch(err) {}
        setState(prev => ({
            ...prev,
            listVisible: false,
            examplePhoneNumber,
            countryCode: code,
            msisdn:{
                value: "",
                internationalFormat: "",
                countryCodeLetters: letters,
            }
        }))
    }

    const handleToggleList = (ev: MouseEvent<HTMLDivElement>) => {
        setState(prev => ({
            ...prev,
            listVisible: !prev.listVisible,
        }))
    }

    const handleMsisdnChange = (ev: ChangeEvent<HTMLInputElement>) => {
        const msisdn = ev.currentTarget?.value
        let msisdnValid = false
        let internationalFormat = ""
        try{
            const i = PhoneNumberUtil.getInstance()
            const phoneNumber = i.parse(msisdn, state.msisdn.countryCodeLetters.toUpperCase())
            msisdnValid = i.isValidNumber(phoneNumber) && i.isPossibleNumber(phoneNumber) && i.isPossibleNumberForType(phoneNumber, PhoneNumberType.MOBILE)
            internationalFormat = i.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL)
        }catch {
        }
        setState(prev => ({
            ...prev,
            msisdnValid,
            msisdn:{
                ...prev.msisdn,
                internationalFormat,
                value: msisdn,
            },
        }))
    }

    const submitDisabled =  !state.msisdnValid || state.loading
    const handleVerify = () => {
        if (submitDisabled) {
            return
        }
        setState(prev => ({...prev, loading: true }))
        verifyMsisdn({
            msisdn: state.msisdn
        }).then((resp) => {
            if (resp.data.blocked) {
                const input= {name: "status", value: "blocked"}
                props.handleSubmitWithNoSave?.(input)
                return
            }
            const input= {name: props.name, value: state.msisdn}
            props.handleSubmit(input)
        }).catch((err) => {
            setState(prev => ({...prev, loading: false }))
        })
    }

    const handleKeyDown = (ev: KeyboardEvent<HTMLInputElement>) => {
        if (ev.key !== "Enter") {
            return
        }
        handleVerify()
    }
    
    const countries = [
        {
            name: "malaysia",
            countryCode: "+60",
            countryCodeLetters: "my",
        },
        {
            name: "singapore",
            countryCode: "+65",
            countryCodeLetters: "sg",
        },
        {
            name: "australia",
            countryCode: "+61",
            countryCodeLetters: "au",
        },
        {
            name: "hongkong",
            countryCode: "+852",
            countryCodeLetters: "hk",
        },
        {
            name: "newzealand",
            countryCode: "+64",
            countryCodeLetters: "nz",
        },
        {
            name: "bahrain",
            countryCode: "+973",
            countryCodeLetters: "bh",
        },
    ]

    return <div className="flex flex-col xs:justify-between sm:justify-between md:justify-normal md:gap-20 h-full">
        <div className="flex flex-col items-start w-full gap-6">
            <h3 className="text-blue-800 font-medium text-xl">
                {props.text}
            </h3>
            <div className="flex w-full">
                <div className="flex gap-1 border-r-0 border-2 border-blue-700 rounded-tl-full rounded-bl-full p-1 pr-6 cursor-pointer" onClick={handleToggleList}>
                    <Flag size={48} className="drop-shadow-md rounded-full cursor-pointer" name={state.msisdn.countryCodeLetters} />
                    <label className={`capitalize cursor-pointer my-auto font-medium`}>{state.countryCode}</label>
                </div>
                {state.listVisible && <div className="fixed">
                    <div className="fixed top-0 w-full h-full" onClick={handleToggleList}></div>
                    <ul className="absolute top-16 w-32 flex flex-col gap-6 pt-5 pb-5 pr-5 pl-1 overflow-auto rounded-br-3xl rounded-bl-3xl bg-white shadow-md">
                        {countries.map(c => {
                            if (state.countryCode === c.countryCode) return <Fragment key={c.countryCodeLetters}></Fragment>
                            return <li key={c.countryCodeLetters} data-letters={c.countryCodeLetters} data-code={c.countryCode} onClick={handleCountryCodeChange} className={`flex gap-3 cursor-pointer`}>
                                    <Flag size={32} className="drop-shadow-lg rounded-full" name={c.countryCodeLetters} />
                                    <label className={`capitalize cursor-pointer my-auto font-medium`}>{c.countryCode}</label>
                            </li>
                        })}
                    </ul>
                </div>}
                <input type="text" autoFocus={true} onKeyDown={handleKeyDown} inputMode="tel" className="w-full p-3 border-2 border-blue-700 rounded-tr-full rounded-br-full border-l-0" style={{boxShadow: "none"}} placeholder={state.examplePhoneNumber} onChange={handleMsisdnChange} value={state.msisdn.value}/>
            </div>
        </div>
        <div className="w-full">
            <button onClick={handleVerify} disabled={submitDisabled} className="w-full p-3 hg-bg-blue text-white rounded-full disabled:bg-blue-300">
                {!state.loading && "Verify"}
                {state.loading && <Loader classNames="w-5 h-5 text-blue-300"/>}
            </button>
        </div>
    </div>
}

interface QuestionVerifyMsisdnState {
    verificationCode: string
    loading: boolean
    verificationCodeValid: boolean
}

interface QuestionVerifyMsisdnProps extends QuestionProps {
    msisdnCountryCodeLetters: string
    msisdnInternationalFormat: string
}

function QuestionVerifyMsisdn(props: QuestionVerifyMsisdnProps) {
    const [state, setState] = useState<QuestionVerifyMsisdnState>({
        loading: false,
        verificationCodeValid: false,
        verificationCode: "",
    })

    const handleVerificationCodeChange = (ev: ChangeEvent<HTMLInputElement>) => {
        const verificationCode = ev.target?.value
        const verificationCodeValid = parseInt(verificationCode) > 0 && verificationCode.length === 6
        setState(prev => ({...prev, verificationCode, verificationCodeValid }))
    }
    
    const submitDisabled =  !state.verificationCodeValid || state.loading
    const handleSubmit = () => {
        if (submitDisabled) {
            return
        }
        setState(prev => ({...prev, loading: true }))
        verifyMsisdnCode(state.verificationCode).then((resp) => {
            if (resp.data.blocked) {
                const input= {name: "status", value: "blocked"}
                props.handleSubmitWithNoSave?.(input)
                return
            }
            if (!resp.data.verified) {
                setState(prev => ({...prev, loading: false }))
                return
            }
            const input= {name: props.name, value: true}
            props.handleSubmitWithNoSave?.(input)
        })
    }

    const handleKeyDown = (ev: KeyboardEvent<HTMLInputElement>) => {
        if (ev.key !== "Enter") {
            return
        }
        handleSubmit()
    }
    

    return <div className="flex flex-col xs:justify-between sm:justify-between md:justify-normal md:gap-20 h-full">
        <div className="flex flex-col items-start w-full gap-6">
            <h3 className="text-blue-800 font-medium text-xl">
                {props.text}
            </h3>
            <div className="flex gap-3 p-1 pr-8">
                <Flag size={48} className="drop-shadow-md rounded-full" name={props.msisdnCountryCodeLetters} />
                <label className={`capitalize my-auto font-medium`}>{props.msisdnInternationalFormat}</label>
            </div>
            <div className="flex w-full">
                <input type="number" inputMode="numeric" autoComplete="one-time-code" autoFocus={true} onKeyDown={handleKeyDown} className="w-full border-2 border-blue-700 rounded-full" style={{boxShadow: "none"}} placeholder="123 456" onChange={handleVerificationCodeChange} value={state.verificationCode}/>
            </div>
        </div>
        <div className="w-full">
            <button onClick={handleSubmit} disabled={submitDisabled} className="w-full p-3 hg-bg-blue text-white rounded-full disabled:bg-blue-300">
                {!state.loading && "Submit"}
                {state.loading && <Loader classNames="w-5 h-5 text-blue-300"/>}
            </button>
        </div>
    </div>
}



interface QuestionSelectProps extends QuestionProps {
    multiple?: boolean
    detailsRenderer?: () => JSX.Element
    options: {
        label: string
        value: string
    }[]
}

function QuestionSelect(props: QuestionSelectProps) {
    const [selected, setSelected] = useState<Set<string>>(new Set<string>())
    const handleSelect = (ev: MouseEvent<HTMLLIElement>) => {
        const value = ev.currentTarget.dataset.value
        if (!value) return
        if (!props.multiple) {
            props.handleSubmit({name: props.name, value})
            return
        }
        setSelected(prev => {
            const set = new Set<string>(prev)
            if (prev.has(value)) {
                set.delete(value)
                return set
            }
            set.add(value)
            return set
        })
    }

    const handleSubmit = () => {
        props.handleSubmit({name: props.name, value: Array.from(selected)})
    }

    return <div className="flex flex-col xs:justify-between sm:justify-between md:justify-normal h-full md:gap-20">
        <div className="flex flex-col items-start w-full gap-6">
            <div className="flex flex-col gap-2">
                <h3 className="text-blue-800 font-medium text-xl">
                    {props.text}
                </h3>
                {props.multiple && <h6 className="text-blue-800 font-medium text-md">
                    Select one or more that apply to you.
                </h6>}
            </div>
            {!props.multiple && <ul className="w-full flex flex-col gap-5">
                {props.options?.map((o) => {
                    return <li key={o.value} onClick={handleSelect} data-value={o.value} className="bg-blue-50 font-medium text-blue-800 border border-blue-800 p-4 rounded-3xl cursor-pointer">{o.label}</li>
                })}
            </ul>}
            {props.multiple && <ul className="w-full flex flex-col gap-5">
                {props.options?.map((o) => {
                    return <li key={o.value} onClick={handleSelect} data-value={o.value} className={`font-medium p-4 rounded-3xl cursor-pointer border border-blue-800 ${!selected.has(o.value) ? "bg-blue-50 text-blue-800" : "bg-blue-800 text-white"}`}>{o.label}</li>
                })}
            </ul>}
            {props.detailsRenderer ? props.detailsRenderer() : <></>}
        </div>

        {props.multiple && <div className="w-full">
            <button onClick={handleSubmit} className="w-full p-3 hg-bg-blue text-white rounded-full disabled:bg-blue-300" disabled={selected.size === 0}>Next</button>
        </div>}
    </div>
}

function QuestionOnfido(props: QuestionProps) {
    useEffect(() => {
        getOnfidoSession().then((resp) => {
            onfido.init({
                token: resp.data.token,
                workflowRunId: resp.data.workflowRunId,
                containerId: "onfido-container",
                disableWelcomeScreen: true,
                enterpriseFeatures: {
                    hideOnfidoLogo: true
                },
                onComplete: (data) => {
                    props.handleSubmit({name: props.name, value: data} as SubmitInput)
                },
                onUserExit: (data) => {
                },
                onError: (data) => {
                },
            })
        }).catch(console.error)
    }, [])
    return <div id="onfido-container"></div>
}

function Outro(props: QuestionProps) {
    useEffect(() => {
        deleteAuthEmail()
    }, [])
    return <div className="flex flex-col xs:justify-between sm:justify-between md:justify-normal md:gap-20 h-full">
        <div className="flex flex-col items-center text-center w-full gap-6">
            <Hourglass />
            <h3 className="text-blue-800 font-medium text-xl">
                Thank you for registering with Halogen!
            </h3>
            <h3 className="text-blue-800 font-medium text-xl">
                Your account is now under review. Please keep an eye on your email inbox; we'll be in touch within 1-2 business days.
            </h3>
        </div>
        <a href="#/" className="text-center w-full p-3 hg-bg-blue text-white rounded-full">Home</a>
    </div>
}


function QuestionDeclaration(props: QuestionProps) {
    const [state, setState] = useState<Set<number>>(new Set<number>())
    const handleSubmit = () => {
        const input= {name: props.name, value: true, waitForServer: true} as SubmitInput
        props.handleSubmit(input)
    }
    const handleToggle = (ev: MouseEvent<HTMLLIElement>) => {
        const index = ev.currentTarget.dataset.index
        if (!index) return
        const indexNum = parseInt(index)
        setState(prev => {
            const set = new Set<number>(prev)
            if (set.has(indexNum)) 
                set.delete(indexNum) 
            else 
                set.add(indexNum)
            return set
        })
    }
    const canConfirm = state.size === 3
    return <div className="flex flex-col gap-3 xs:justify-between sm:justify-between md:justify-normal md:gap-20 h-full">
        <div className="flex flex-col items-start w-full gap-3">
            <h3 className="text-blue-800 font-medium text-xl">
                Final step! Complete the declaration by tapping on all the following items.
            </h3>
            <h3 className="font-medium">I hereby declare and confirm that:</h3>
            <ul className="flex flex-col gap-3 font-medium">
                <li onClick={handleToggle} data-index="0" className={`p-5 rounded-3xl border-2 border-blue-800 cursor-pointer ${state.has(0) ? "bg-blue-800 text-white" : "bg-blue-50 text-blue-800"}`}>
                    All the information provided in this application are true.
                </li>
                <li onClick={handleToggle} data-index="1" className={`p-5 rounded-3xl border-2 border-blue-800 cursor-pointer ${state.has(1) ? "bg-blue-800 text-white" : "bg-blue-50 text-blue-800"}`}>
                    I am not a US citizen and I do not have a US passport.
                </li>
                <li onClick={handleToggle} data-index="2" className={`p-5 rounded-3xl border-2 border-blue-800 cursor-pointer ${state.has(2) ? "bg-blue-800 text-white" : "bg-blue-50 text-blue-800"}`}>
                    I have read and agreed to the Terms and Conditions, Privacy Policy and User Agreement.
                </li>
            </ul>
            <p className="text-blue-800 text-sm font-medium">
                {/*  and <a href="#" className="text-blue-600 underline font-medium" target="_blank">User Agreement</a> */}
                Refer to <a href="https://halogen.my/disclaimers/terms-of-use" className="text-blue-600 underline font-medium" target="_blank">Terms and Conditions</a> and <a href="https://halogen.my/disclaimers/privacy-policy" className="text-blue-600 underline font-medium" target="_blank">Privacy Policy</a>.
            </p>
        </div>
        <div className="w-full">
            <button onClick={handleSubmit} className="w-full p-3 hg-bg-blue text-white rounded-full disabled:bg-blue-300" disabled={!canConfirm}>
                {!canConfirm &&  "Tap all boxes above to continue (" + state.size + "/3)" }
                {canConfirm && "Confirm and submit"}
            </button>
        </div>
    </div>
}